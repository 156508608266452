import {
  PEClampResultsDisplay,
  PEClampType,
} from '@pe/components/Configure/RuleDetails/RulesLogic.js';
import {
  ADJUSTMENT_KIND,
  ADJUSTMENT_NAME_MATCH,
  AUTOMATED_UNDERWRITING_SYSTEM,
  FORMATTED_EXCEPTION_TYPES,
  invertObject,
  loanTypes,
  LOCK_CONFIRM,
  LOCK_RATE_ACTIONS,
  LOCK_RATE_STATUS,
  LOCK_REQUEST_STATUS,
  occupancyCodes,
  OP_NAME,
  PEImpoundOptions,
  peOccupancyTypesToStr,
  pePropertyAttachmentTypesToStr,
  pePropertyTypesToStr,
  PERuleAdjustmentsDisplayOrder,
  PERuleCategories,
  PERuleCategoriesDisplay,
  pricerModes,
  PRICING_ROUND,
  ROUNDING_RULE_NAME,
  propertyAttachmentCodes,
  propertyCodes,
  RELOCK_CONFIRM,
  REPRICE_CONFIRM,
  PRODUCT_CHANGE_CONFIRM,
  ROUTE_TO_BREADCRUMB_CONVERTERS,
  RULE_TARGET,
  WAIVE_ESCROW_OPTIONS,
  WORKFLOW_POLICY_DISPLAY,
  PERuleSubCategories,
  WORKFLOW_MODES,
  WORKFLOW_STEPS,
  compPaidByTypes,
  compPaidBy,
  LOS_WRITEBACK_QUEUE_ITEM_STATUS,
} from '@shared/constants';

import {
  createLockRequestCustomCredit,
  getDynamicPricingDetails,
} from '@pe/services/configurations.js';
import { getTerms } from '@pe/services/loanTerms.js';

import api from '@shared/services/api.js';
import {
  formatMoney,
  strToPrecision,
  roundDecimals,
  formatInTimeZone,
  getAbbreviatedTimezone,
} from '@shared/utils/converters.js';
import lodash from 'lodash';
import { onErrorHandler } from '@shared/utils/errorHandlers';
import { createCustomCreditToFloatRequest } from '@pe/services/lockRequests';
import isEmpty from 'lodash/isEmpty';

const invertedPropertyAttachmentCodes = invertObject(propertyAttachmentCodes);
const invertedPropertyCodes = invertObject(propertyCodes);
const invertedOccupancyCodes = invertObject(occupancyCodes);

/**
 * Some of these helper functions are duplicated in the python code
 * pe.services.reprice_dict_builder
 * If we change here we will want to make some changes there and vice versa
 * PEA-1273
 */

/**
 * Filter adjustments to include Extension Fees, Relock Fees, and Exceptions.
 * Returns array of stripped down adjustment object.
 *
 * @export
 * @param {Object} lockRequest
 * @returns {Array}
 */
export function getPersistedAdjustmentsFromLockRequest(lockRequest) {
  const currentAdjustments = lockRequest.adjustments || [];
  const exceptions = formatExceptionsAsAdjustments(lockRequest.priceExceptions);

  return [...currentAdjustments, ...exceptions]
    .filter(
      adjustment =>
        adjustment.kind == ADJUSTMENT_KIND.EXTENSION_FEE ||
        adjustment.kind == ADJUSTMENT_KIND.RELOCK_FEE ||
        // PEK-245: Due to an Encompass issue, we need to treat old Extension Fees
        // as normal adjustments after relock (but still need to persist).
        adjustment.description?.startsWith('Extension Fee') ||
        adjustment.description?.startsWith(ADJUSTMENT_NAME_MATCH.EXCEPTION) ||
        adjustment.description?.startsWith('Polly Lock '),
    )
    .map(adjustment => ({
      amount: adjustment.value,
      description: adjustment.description,
      kind: adjustment.kind,
      type: adjustment.type,
      target: adjustment.target,
      is_profitability_rule: adjustment.is_profitability_rule,
      rule_sub_category: adjustment.rule_sub_category,
      hide_adjustment_in_base_pricing:
        adjustment.hide_adjustment_in_base_pricing,
    }));
}

/**
 * Mutates exception descriptions and returns an array of adjustments.
 *
 * @export
 * @param {Array} exceptions
 * @returns {Array}
 */
export function formatExceptionsAsAdjustments(exceptions) {
  if (!exceptions) return [];
  return exceptions.map(exception => {
    const exceptionType = FORMATTED_EXCEPTION_TYPES[exception.type];
    exception.description = `Exception (${exceptionType})`;
    return exception;
  });
}

export function getPricingRequestBodyFromLockRequest(
  lockRequest,
  persistedAdjustments,
  tenantUTCOffset,
) {
  const { originalPriceRequest } = lockRequest;

  const Loan = originalPriceRequest.loan;
  Loan.aus = AUTOMATED_UNDERWRITING_SYSTEM[Loan.aus];
  const Property = originalPriceRequest.property;
  const occupancyString = peOccupancyTypesToStr[Property.occupancy];
  const occupancyCode = invertedOccupancyCodes[occupancyString];
  Property.occupancy = occupancyCode;
  const propertyAttachmentTypeString =
    pePropertyAttachmentTypesToStr[Property.propertyAttachmentType];
  const propertyTypeString = pePropertyTypesToStr[Property.propertyType];
  const propertyTypeCode = invertedPropertyCodes[propertyTypeString];
  const propertyAttachmentCode =
    invertedPropertyAttachmentCodes[propertyAttachmentTypeString];
  Property.propertyType = propertyTypeCode;
  Property.propertyAttachmentType = propertyAttachmentCode;
  const Search = originalPriceRequest.search;
  Search.expirationDate = lockRequest.expirationDate;
  if ([undefined, null, ''].includes(tenantUTCOffset)) {
    tenantUTCOffset = originalPriceRequest.tenantUTCOffset;
  }
  return {
    CountyLoanLimitYearToUse: originalPriceRequest.countyLoanLimitYearToUse,
    Adjustments: persistedAdjustments,
    AudienceId: originalPriceRequest.audienceId,
    BaseRateSetId: originalPriceRequest.baseRateSetId,
    ChangesetId: originalPriceRequest.changesetId,
    Search,
    Borrower: originalPriceRequest.borrower,
    Loan,
    Property,
    BrokerCompPlan: originalPriceRequest.brokerCompPlan,
    CustomValues: Object.keys(originalPriceRequest.customValues).length
      ? originalPriceRequest.customValues
      : [],
    InitialLockDateTime: originalPriceRequest.initialLockDateTime,
    TenantUTCOffset: tenantUTCOffset,
    MortgageInsurance: originalPriceRequest.mortgageInsurance ?? {},
    ProductOverrides: originalPriceRequest.productOverrides ?? [],
  };
}

/**
 * Gets clamp adjustment for a particular price from PE3 response data
 * @param {Array} clampResults found on data.results[].prices[].clampResults
 * @returns {Array} clamp adjustments for a particular price
 */
export function getClampAdjustments(clampResults) {
  const adjustments = [];
  clampResults.forEach(result => {
    if (result.clamped !== result.unclamped) {
      const categoryDisplay =
        result.category === PEClampType.TotalPrice
          ? undefined
          : PEClampResultsDisplay[result.category];
      const minMax = result.clamped > result.unclamped ? 'Max' : 'Min';
      const totalPriceMinMax =
        result.clamped < result.unclamped ? 'Max' : 'Min';
      const investorLabel = result.isInherited ? 'Investor' : 'Client';
      const name =
        result.category === PEClampType.TotalPrice
          ? `${investorLabel} ${totalPriceMinMax} ${result.target} Adjustment`
          : `${investorLabel} ${minMax} ${categoryDisplay} ${result.target} Adjustment`;
      const isProfitability = result.category === PEClampType.Margin;
      const value = Number(result.clamped) - Number(result.unclamped);
      adjustments.push({
        category: result.category,
        isClampRule: true,
        target: result.target,
        resultEquationValue: value,
        adjustment: strToPrecision(value, 3),
        ruleName: name,
        isInherited: result.isInherited,
        isProfitabilityRule: isProfitability,
      });
    }
  });

  return adjustments;
}

/**
 * Gets adjustment for a particular price from PE3 response data
 * @param {Object} price found on data.results[].prices[]
 * @returns {Array} adjustments for a particular price
 */
export function getActivePriceAdjustments(price) {
  if (price.ruleResults.length > 0) {
    return price.ruleResults
      .filter(record => record.target === RULE_TARGET.PRICE)
      .filter(record => {
        return (
          record.resultEquationValue !== 0 && record.resultEquationValue != null
        );
      })
      .map(adjustment => ({
        ...adjustment,
        adjustment: strToPrecision(Number(adjustment.resultEquationValue), 3),
      }));
  } else {
    return [];
  }
}

/**
 * Formats rule results from PE3 response data
 * @param {Array} productRuleResults found on data.results[].ruleResults
 * @returns {Array} formatted rule results
 */
export function getProductAdjustments(productRuleResults) {
  return productRuleResults
    .filter(
      adjustment =>
        'resultEquationValue' in adjustment &&
        adjustment.resultEquationValue !== 0,
    )
    .map(adjustment => ({
      ...adjustment,
      adjustment: strToPrecision(Number(adjustment.resultEquationValue), 3),
    }));
}

export function getCombinedAdjustments(product, selectedPrice) {
  const activePriceAdjustments = getActivePriceAdjustments(selectedPrice);
  const clampAdjustments = getClampAdjustments(selectedPrice.clampResults);
  const productAdjustments = getProductAdjustments(product.ruleResults);
  return [
    ...clampAdjustments,
    ...activePriceAdjustments,
    ...productAdjustments,
  ];
}

export function shouldForceReprice(
  currentOrInitialLock,
  losLoanData,
  orgPermissions,
) {
  // cant tell anything if loan data hasn't been loaded yet
  if (!losLoanData) return false;

  const requiresRepriceOnForceReprice =
    losLoanData.requiresReprice && orgPermissions.has_force_reprice_enabled;
  const actionRequiredOnAutoTriggeredReprice =
    currentOrInitialLock &&
    currentOrInitialLock.actionRequired &&
    orgPermissions.has_auto_triggered_reprice_enabled;
  const notExpired = losLoanData.lockRateStatus !== LOCK_RATE_STATUS.EXPIRED;
  return (
    (requiresRepriceOnForceReprice || actionRequiredOnAutoTriggeredReprice) &&
    notExpired
  );
}

export function getModeAndWorkflowFromLoanData(losLoanData, orgPermissions) {
  let pricerMode = pricerModes.lock;
  let workflow = 'LOCK.CONFIRMED';
  let crossWorkflow = null;

  if (
    [
      LOS_WRITEBACK_QUEUE_ITEM_STATUS.PENDING,
      LOS_WRITEBACK_QUEUE_ITEM_STATUS.PROCESSING,
      LOS_WRITEBACK_QUEUE_ITEM_STATUS.RETRYING,
    ].includes(losLoanData.floatRequest?.writebackStatus)
  ) {
    workflow = 'FLOAT_REQUEST.PENDING';
    return { workflow, pricerMode, crossWorkflow };
  }

  const currentOrInitialLock =
    losLoanData.lockRequests.currentLock ||
    losLoanData.lockRequests.initialLock;

  const isCrossWorkflowInProgress =
    losLoanData.lockRequests?.pendingLock?.lockRequestStatus ===
    LOCK_REQUEST_STATUS.BUNDLE_PENDING;
  const isCrossWorkflowPending =
    !!losLoanData.lockRequests?.pendingLock?.childLockRequests?.length;

  //We only suppose price exception cross workflows, this will need to be expanded when we add more cross workflows
  if (
    isCrossWorkflowInProgress &&
    orgPermissions.has_auto_expand_pe_by_default
  ) {
    crossWorkflow = 'EXCEPTION.SELECT';
  } else if (isCrossWorkflowPending) {
    crossWorkflow = 'EXCEPTION.PENDING';
    return { workflow, pricerMode, crossWorkflow };
  }
  const canForceReprice =
    losLoanData.lockRequests?.pendingLock == null &&
    shouldForceReprice(currentOrInitialLock, losLoanData, orgPermissions);

  if (canForceReprice) {
    workflow = 'FORCE_REPRICE.SELECT';
    return { workflow, pricerMode, crossWorkflow };
  }

  switch (losLoanData.lockRateStatus) {
    case LOCK_RATE_STATUS.EXTENSION_PENDING:
      workflow = 'EXTENSION.PENDING';
      break;
    case LOCK_RATE_STATUS.RELOCK_PENDING:
      workflow = 'RELOCK.PENDING';
      break;
    case LOCK_RATE_STATUS.REPRICE_PENDING:
      workflow = 'REPRICE.PENDING';
      pricerMode = pricerModes.reprice;
      break;
    case LOCK_RATE_STATUS.PRICE_EXCEPTION_PENDING:
      workflow = 'EXCEPTION.PENDING';
      break;
    case LOCK_RATE_STATUS.FLOAT_DOWN_PENDING:
      pricerMode = pricerModes.floatDown;
      workflow = 'FLOAT_DOWN.PENDING';
      break;
    case LOCK_RATE_STATUS.CANCELLATION_PENDING:
      workflow = 'CANCEL.PENDING';
      break;
    case LOCK_RATE_STATUS.CANCELLED:
      workflow = 'CANCEL.COMPLETE';
      break;
    case LOCK_RATE_STATUS.PRODUCT_CHANGE_PENDING:
      workflow = 'PRODUCT_CHANGE.PENDING';
      break;
    case LOCK_RATE_STATUS.RESET_PENDING:
      workflow = 'RESET.PENDING';
      break;
    case LOCK_RATE_STATUS.NOT_LOCKED:
      workflow = null;
  }

  return { workflow, pricerMode, crossWorkflow };
}

export function getModeAndWorkflowOverride(type) {
  let workflowOverride = null;
  let pricerModeOverride = pricerModes.lock;

  switch (WORKFLOW_POLICY_DISPLAY[type]) {
    case WORKFLOW_POLICY_DISPLAY.Extension:
      workflowOverride = 'EXTENSION.SELECT';
      break;
    case WORKFLOW_POLICY_DISPLAY.Relock:
      workflowOverride = 'RELOCK.SELECT';
      pricerModeOverride = pricerModes.relock;
      break;
    case WORKFLOW_POLICY_DISPLAY.Reprice:
      workflowOverride = 'REPRICE.SELECT';
      pricerModeOverride = pricerModes.reprice;
      break;
    case WORKFLOW_POLICY_DISPLAY.PriceException:
      workflowOverride = 'EXCEPTION.SELECT';
      break;
    case WORKFLOW_POLICY_DISPLAY.FloatDown:
      pricerModeOverride = pricerModes.floatDown;
      workflowOverride = 'FLOAT_DOWN.SELECT';
      break;
    case WORKFLOW_POLICY_DISPLAY.ProductChange:
      pricerModeOverride = pricerModes.productChange;
      workflowOverride = 'PRODUCT_CHANGE.SELECT';
      break;
    case WORKFLOW_POLICY_DISPLAY.Cancel:
      workflowOverride = 'CANCEL.CONFIRM';
      break;
    case WORKFLOW_POLICY_DISPLAY.Reset:
      workflowOverride = 'RESET.CONFIRM';
      break;
    case WORKFLOW_POLICY_DISPLAY.Renegotiation:
      workflowOverride = 'RENEGOTIATION.SELECT';
      break;
  }

  return { workflowOverride, pricerModeOverride };
}

export class GetPriceAdjustmentTotalOptions {
  constructor(
    adjustments = [],
    baseRateSetId = null,
    audienceId = null,
    productId = null,
    rate = null,
    lockPeriod = null,
    analysisMode = null,
  ) {
    this.adjustments = adjustments;
    this.baseRateSetId = baseRateSetId;
    this.audienceId = audienceId;
    this.productId = productId;
    this.rate = rate;
    this.lockPeriod = lockPeriod;
    // Whether we are getting the price adjustment totals while running a sell side analysis
    this.analysisMode = analysisMode;
  }
}

export class GetPriceAdjustmentTotalResult {
  constructor(margin = 0, srp = 0) {
    this.totalMargin = margin;
    this.totalSrp = srp;
  }
}

/**
 * Combine scenario adjustments and base rate detail adjustments to determine the
 * total SRP and Margin adjustments made.
 * @param {GetPriceAdjustmentTotalOptions} options used to get base rate details.
 * @param {Object} requestOptions options to be passed to the request handler.
 * @returns {Promise<GetPriceAdjustmentTotalResult>} adjustment totals by category.
 */
export async function getPriceAdjustmentTotals(options, requestOptions) {
  getPriceAdjustmentTotals_validateOptions(options);

  let ruleResults = options.adjustments;

  // Only get the extra rate details if we aren't doing a sell side analysis.
  // If we are running a sell side analysis then use the adjustments provided to compute the total SRP and margin.
  // In a sell side analysis we are given the investor rates not the tenant rates
  if (!options.analysisMode) {
    const url = getPriceAdjustmentTotals_buildUrl(options);

    // Get details of all the adjustments that were made to get from an investor
    // rate to a client base rate and add them to the combined list of adjustments.
    const detailResponse = await api.get(url, requestOptions);
    if (detailResponse) {
      if (detailResponse.ruleResults) {
        ruleResults = ruleResults.concat(detailResponse.ruleResults);
      }
      if (detailResponse.baseRateDetails?.[0]?.ruleResults) {
        ruleResults = ruleResults.concat(
          detailResponse.baseRateDetails[0].ruleResults,
        );
      }
    }
  }

  return calculatePriceAdjustmentTotalsFromRules(ruleResults);
}

/**
 * Calculates SRP and MARGIN totals from all of the adjustments
 * that were made from investor -> base rate and from base rate -> pricing
 * scenario.
 * @param {Array} ruleResults results of rules ran for the product
 * @returns {GetPriceAdjustmentTotalResult} adjustment results by category
 */
export function calculatePriceAdjustmentTotalsFromRules(ruleResults) {
  let totalMargin = 0;
  let totalSrp = 0;

  for (const result of ruleResults) {
    if (result.ruleName === 'Pricing Rounded') {
      totalMargin += Number(result.resultEquationValue);
      continue;
    }

    switch (result.category) {
      case PERuleCategories.SRP:
        totalSrp += Number(result.resultEquationValue);
        break;

      case PERuleCategories.Margin:
        if (result.target !== RULE_TARGET.ARM_MARGIN) {
          // ARM Margin does not factor in to total margin
          totalMargin += Number(result.resultEquationValue);
        }
        break;
    }
  }

  // Return what we've found in a simple little package.
  return new GetPriceAdjustmentTotalResult(totalMargin, totalSrp);
}

/**
 * Validate options to ensure all the required values are present.
 * @param {GetPriceAdjustmentTotalOptions} options used to get base rate details.
 * @returns {void} No return value.
 */
function getPriceAdjustmentTotals_validateOptions(options) {
  if (!options) {
    throw Error('options must be provided.');
  }
  if (!options.adjustments) {
    throw Error('options.adjustments must be provided.');
  }
  // These options are only required when not running a sell side analysis
  if (!options.analysisMode) {
    if (!options.baseRateSetId) {
      throw Error('options.baseRateSetId must be provided.');
    }
    if (!options.audienceId) {
      throw Error('options.audienceId must be provided.');
    }
  }
  if (!options.productId) {
    throw Error('options.productId must be provided.');
  }
}

/**
 * Calculate URL to get base rate detail information.
 * @param {GetPriceAdjustmentTotalOptions} options used to get base rate details.
 * @returns {string} Relative URL to base rate detail API.
 */
function getPriceAdjustmentTotals_buildUrl(options) {
  let url = `/pe/api/rates/${options.baseRateSetId}/details/${options.audienceId}/${options.productId}`;
  if (options.rate && options.lockPeriod) {
    url += `?rate=${options.rate}&lockPeriod=${options.lockPeriod}`;
  } else if (options.lockPeriod) {
    url += `?lockPeriod=${options.lockPeriod}`;
  } else if (options.rate) {
    url += `?rate=${options.rate}`;
  }

  return url;
}

export function getPE3ResultFromLockRequest(lockRequest) {
  const { results } = lockRequest.originalPriceRequest || {};
  return results?.find(prod => prod.code === lockRequest.productCode);
}

export function getPricerModeForDisplay(
  pricerModeType,
  lockRateStatus,
  lockRequest,
) {
  if (
    lockRequest?.childLockRequests &&
    lockRequest?.childLockRequests?.length
  ) {
    // TODO: V2 CWF base this off of the data and not this one crossworkflow
    return `${WORKFLOW_POLICY_DISPLAY.Lock} with ${WORKFLOW_POLICY_DISPLAY.PriceException}`;
  }
  if (pricerModeType == pricerModes.lock) {
    if (lockRateStatus == LOCK_RATE_STATUS.EXTENSION_PENDING) {
      return WORKFLOW_POLICY_DISPLAY.Extension;
    } else if (lockRateStatus == LOCK_RATE_STATUS.PRICE_EXCEPTION_PENDING) {
      return WORKFLOW_POLICY_DISPLAY.PriceException;
    }
    return WORKFLOW_POLICY_DISPLAY.Lock;
  } else if (pricerModeType == pricerModes.reprice) {
    return WORKFLOW_POLICY_DISPLAY.Reprice;
  } else if (pricerModeType == pricerModes.relock) {
    return WORKFLOW_POLICY_DISPLAY.Relock;
  } else if (pricerModeType == pricerModes.floatDown) {
    return WORKFLOW_POLICY_DISPLAY.FloatDown;
  } else {
    return '';
  }
}

export function getKeyByValue(constant, value) {
  return Object.keys(constant).find(key => constant[key] === value);
}

export function getCurrentActiveLockObject(lockRequests) {
  // get the current lock object or the original as a back-up
  return (
    lockRequests?.currentLock ??
    lockRequests?.initialLock ??
    lockRequests?.pendingLock
  );
}

export function filterPersistedAdjustments(adjustments) {
  return adjustments.filter(
    adjustment =>
      adjustment.kind !== ADJUSTMENT_KIND.EXTENSION_FEE &&
      adjustment.kind !== ADJUSTMENT_KIND.RELOCK_FEE &&
      !adjustment.description?.startsWith('Extension Fee') &&
      !adjustment.description?.startsWith(ADJUSTMENT_NAME_MATCH.EXCEPTION),
  );
}

/**
 * Return a filtered set of adjustments suitable for making sell-side analysis request.
 * @param adjustments
 * @returns {*}
 */
export function filterAdjustmentsForSellSide(adjustments) {
  return adjustments.filter(
    adjustment =>
      !adjustment.description?.startsWith(ADJUSTMENT_NAME_MATCH.EXCEPTION),
  );
}

export function filterOnAllObjectValues(arrayValue, searchString) {
  const filterString = searchString.toLowerCase();
  return Object.values(arrayValue).some(val =>
    String(val).toLowerCase().includes(filterString),
  );
}

/**
 * sort date comparator.
 * @param {a} date1
 * @param {b} date2
 * @returns {Number} [-1|0|1] depending on date comparison
 */
export function sortByLastModifiedDesc(a, b) {
  const aLastModified = new Date(a.last_modified);
  const bLastModified = new Date(b.last_modified);

  if (aLastModified < bLastModified) {
    return 1;
  }
  if (aLastModified > bLastModified) {
    return -1;
  }

  return 0;
}

export async function getDynamicPricingRateDetails(
  price,
  requestId,
  isDynamicallyPriced,
) {
  const emptyResult = {
    ruleResults: [],
    clampResults: [],
  };

  if (!isDynamicallyPriced) {
    return emptyResult;
  }

  const details = await getDynamicPricingDetails(
    requestId,
    price.productId,
    price.lockPeriod,
    price.rate,
  );

  const rateDetails = details ? details : emptyResult;
  rateDetails.ruleResults = rateDetails.ruleResults.filter(
    ruleResult => ruleResult.ruleId,
  );
  return rateDetails;
}

export function extendDynamicPricingAdjustments(dynamicPricingDetails) {
  const dynamicPricingDetailsCopy = JSON.parse(
    JSON.stringify(dynamicPricingDetails),
  );
  [
    ...dynamicPricingDetailsCopy.ruleResults,
    ...dynamicPricingDetailsCopy.clampResults,
  ].forEach(result => {
    result.investorId = dynamicPricingDetailsCopy.investorId;
    result.investorChangesetId = dynamicPricingDetailsCopy.investorChangesetId;
    result.isDynamicallyPriced = true;
  });
  return dynamicPricingDetailsCopy;
}

export function getCombinedClampResults(
  clampResults,
  dynamicPricingClampResults,
) {
  return clampResults.map(result => {
    const dpClampResult = dynamicPricingClampResults.find(
      dpResult =>
        dpResult.category === result.category &&
        dpResult.target === result.target,
    ) ?? { clamped: 0, unclamped: 0 };

    return {
      ...result,
      clamped: result.clamped + dpClampResult.clamped,
      unclamped: result.unclamped + dpClampResult.unclamped,
    };
  });
}

export function getCategoryTitle(category) {
  const adjustmentCategories = {
    ...PERuleCategoriesDisplay,
    ...PEClampResultsDisplay,
  };
  return `${adjustmentCategories[category]} Adjustments:`;
}

export function getSortedAdjustmentsByCategory(adjustments) {
  return adjustments.sort(
    (a, b) =>
      PERuleAdjustmentsDisplayOrder[a.category] -
      PERuleAdjustmentsDisplayOrder[b.category],
  );
}

export const loanTotalByType = (
  loanType,
  loanData,
  currentWorkflow,
  pricingTemplate,
) => {
  // very specific function created to deal with a problem between reprice/lock workflows types and the rest
  // check before using for more general purposes.
  const float_select = `${WORKFLOW_MODES.FLOAT_REQUEST}.${WORKFLOW_STEPS.SELECT}`;
  loanType = loanType.toLowerCase();
  const lock = getCurrentActiveLockObject(loanData.lockRequests);
  const loanTotals = [
    LOCK_CONFIRM,
    REPRICE_CONFIRM,
    RELOCK_CONFIRM,
    PRODUCT_CHANGE_CONFIRM,
    float_select,
  ].includes(currentWorkflow)
    ? {
        conventional: pricingTemplate.loan.amount.value,
        fha: pricingTemplate.loan.fhaTotalLoanAmount.value,
        usda: pricingTemplate.loan.usdaTotalLoanAmount.value,
        va: pricingTemplate.loan.vaTotalLoanAmount.value,
        nonqm: pricingTemplate.loan.amount.value,
        jumbo: pricingTemplate.loan.amount.value,
      }
    : {
        conventional: lock.originalPriceRequest.loan.amount,
        fha: lock.originalPriceRequest.loan.fhaTotalLoanAmount,
        usda: lock.originalPriceRequest.loan.usdaTotalLoanAmount,
        va: lock.originalPriceRequest.loan.vaTotalLoanAmount,
        nonqm: lock.originalPriceRequest.loan.amount,
        jumbo: lock.originalPriceRequest.loan.amount,
      };

  return loanTotals[loanType];
};

export const getPreviousLoanValue = (
  property,
  mode,
  orgPermissions,
  previousLoanUpdates,
  converter = null,
) => {
  if (
    (mode === pricerModes.reprice || mode === pricerModes.productChange) &&
    orgPermissions.has_force_reprice_enabled
  ) {
    let previousValue = lodash.get(previousLoanUpdates, property, undefined);
    if (converter && typeof converter === 'function') {
      previousValue = converter(previousValue);
    }
    return previousValue;
  }
};

export function convertImpoundToWaiveEscrow(impoundText) {
  const impoundOption = PEImpoundOptions.find(
    option => option.text === impoundText,
  );
  if (impoundOption) {
    const waiveEscrowOption = WAIVE_ESCROW_OPTIONS.find(
      option => option.id === impoundOption.id,
    );
    return waiveEscrowOption ? waiveEscrowOption.text : impoundText;
  }
  return impoundText;
}

export function formatFee(feeAmount, loanAmount) {
  return `${strToPrecision(feeAmount, 3)} (${calculateTotalFeeAmount(
    feeAmount,
    loanAmount,
  )})`;
}

export function calculateTotalFeeAmount(feeAmount, loanAmount) {
  const dollarAmount = (feeAmount * loanAmount) / 100;
  return formatMoney(dollarAmount, false, 0, '$', '.', ',', true);
}

export function getCurrentOrInitialLock(loanData) {
  const currentOrInitialLock =
    loanData.lockRequests.currentLock || loanData.lockRequests.initialLock;

  return {
    ...currentOrInitialLock,
    ...getTerms(
      currentOrInitialLock?.productCode,
      currentOrInitialLock.originalPriceRequest,
    ),
  };
}

export function getRuleCategory({
  adjustmentDescription,
  isClamp,
  defaultCategory,
}) {
  const isPricingRounded = adjustmentDescription === PRICING_ROUND;
  if (isClamp) {
    return PERuleCategories.Adjustment;
  } else if (isPricingRounded) {
    return PERuleCategories.Margin;
  }
  return defaultCategory;
}

export function getLoanAmount(loanType, loan) {
  if (loanTypes[loanType] === loanTypes.FHA) {
    return loan?.fhaTotalLoanAmount?.value || loan?.fhaTotalLoanAmount;
  } else if (loanTypes[loanType] === loanTypes.USDA) {
    return loan?.usdaTotalLoanAmount?.value || loan?.usdaTotalLoanAmount;
  } else if (loanTypes[loanType] === loanTypes.VA) {
    return loan?.vaTotalLoanAmount?.value || loan?.vaTotalLoanAmount;
  } else {
    return loan?.amount?.value || loan?.amount || loan?.loanAmount;
  }
}

/**
 * Calculates Credit / Cost for a lock request
 * @param {number} finalPrice final price of a lock request
 * @param {number} loanAmount dollar amount of loan
 * @returns {string} Formatted string ex 2.0321% ($6094)
 */
export function calculateCreditCost(finalPrice, loanAmount) {
  /*
    Due to having the expectation that the credit/cost and final price will add up so that
      credit/cost = 100 - rounded net price
   */
  const percentageAmount = roundDecimals(100 - roundDecimals(finalPrice, 3), 3);
  return `${strToPrecision(percentageAmount, 3)} (${calculateTotalFeeAmount(
    percentageAmount,
    loanAmount,
  )})`;
}

/**
 * Calculates Gain / Loss for a lock request
 * @param {number} sellSideNetPrice sell side final price of a lock request
 * @param {number} buySideNetPrice buy side final price of a lock request
 * @param {number} loanAmount dollar amount of loan
 * @returns {string|null} Formatted string ex 2.0321% ($6094)
 */
export function calculateGainLoss(
  sellSideNetPrice,
  buySideNetPrice,
  loanAmount,
) {
  if (!sellSideNetPrice || !buySideNetPrice || !loanAmount) return null;

  const deltaNetPrice = sellSideNetPrice - buySideNetPrice;
  return `${strToPrecision(deltaNetPrice, 3)} (${calculateTotalFeeAmount(
    deltaNetPrice,
    loanAmount,
  )})`;
}

export function getCreditCost(loanType, netPrice, loan) {
  const amount = getLoanAmount(loanType, loan);
  return calculateCreditCost(netPrice, amount);
}

export function getGainLoss(loanType, sellSideNetPrice, buySideNetPrice, loan) {
  if (!loanType || !sellSideNetPrice || !buySideNetPrice || !loan) {
    return null;
  }

  const amount = getLoanAmount(loanType, loan);
  return calculateGainLoss(sellSideNetPrice, buySideNetPrice, +amount);
}

export function routeToBreadcrumb(from, extraData) {
  return ROUTE_TO_BREADCRUMB_CONVERTERS[from?.name]?.(from, extraData);
}

export function isInOverrides(field, overrides = {}, isUserLockDeskOrHigher) {
  if (!isUserLockDeskOrHigher) {
    return false;
  }
  return !!overrides?.[field];
}

export function isFieldOverridden(field, overrides, originalData) {
  return (
    isInOverrides(field, overrides, true) &&
    overrides?.[field] !== originalData?.[field]
  );
}

export function expirationDateLockPeriodOverrideName(action) {
  let lockFieldName = 'lock_period';
  if (action == LOCK_RATE_ACTIONS.RELOCK) lockFieldName = 'relock_period';
  if (action == LOCK_RATE_ACTIONS.EXTENSION) lockFieldName = 'days_to_extend';
  return lockFieldName;
}

export function getAddedRoundingAdjustments(
  filteredPrice,
  priceAdjustments,
  ruleAdjustments,
) {
  // looks to see if there are rounding rules which need to be added to adjustments when
  //   preparing price in pricer UI
  const roundingAdjustment = ruleAdjustments.filter(
    adjustment => adjustment.category === 'Rounding',
  )[0];
  if (!roundingAdjustment) {
    return [];
  }
  const channelRoundingAdjustments = priceAdjustments.filter(
    adjustment => adjustment.ruleName == 'Pricing Rounded',
  );
  let channelAdjustment = 0.0;
  if (channelRoundingAdjustments.length > 0) {
    const thisAdjustment = channelRoundingAdjustments[0];
    channelAdjustment = parseFloat(thisAdjustment.adjustment);
  }
  const adjustmentVal =
    filteredPrice.netPrice -
    filteredPrice.netPriceBeforeRounding -
    channelAdjustment;
  // if there's a channel adjustment, it probably already takes into account any rounding rule adjustment
  const adjustmentStr = strToPrecision(Number(adjustmentVal), 3);
  // compensate for javascript arithmetic
  if (adjustmentStr === '0.000') {
    return [];
  }
  const newRule = { ...roundingAdjustment };
  //capture category since we change it to Margin
  newRule['originalCategory'] = newRule['category'];
  newRule['ruleName'] = 'Rounding Rule Adjustments';
  newRule['category'] = 'Margin';
  newRule['adjustment'] = adjustmentStr;
  const roundingResults = [];
  roundingResults.push(newRule);
  return roundingResults;
}

export function isObject(obj) {
  return obj != null && obj.constructor.name === 'Object';
}

// If the adjustment has a sub category, go based on the roles, otherwise go down the old path.
// TODO: Remove the old way once after there's a realistic time for everyone to set the sub category.
export function adjustmentFilter(adj, adjPermissions, isUserLockDeskOrHigher) {
  if (isUserLockDeskOrHigher) return true;

  const subCategory = adj.subCategory || adj.rule_sub_category;
  const hideAdjustmentInBasePricing =
    adj.hideAdjustmentInBasePricing ||
    adj.hide_adjustment_in_base_pricing ||
    adj.isHiddenAdjustment;

  const isRoundingRule =
    // unfortunately a way to capture certain rounding adjustment is by checking the description
    adj.description?.includes(PRICING_ROUND) ||
    adj.description?.includes(ROUNDING_RULE_NAME) ||
    // this method is likely depreciated for newly created adjustments, but is still required for legacy data
    isRoundingRuleAdjustment(adj);

  const isClampMargin =
    adj.clamp_category === PERuleCategories.Margin || // lock history
    (adj.isClampRule && adj.category === PERuleCategories.Margin); // scenario

  if (isRoundingRule) {
    if ([null, PERuleSubCategories.None].includes(subCategory)) {
      return false;
    } else {
      return adjPermissions[subCategory];
    }
  } else if (isClampMargin) {
    return false;
  } else if (
    ![null, PERuleSubCategories.None].includes(subCategory) &&
    hideAdjustmentInBasePricing
  ) {
    return adjPermissions[subCategory];
  } else {
    return !hideAdjustmentInBasePricing;
  }
}

export function adjSubCategoryLabel(subCategory) {
  if (!subCategory || subCategory === PERuleSubCategories.None) {
    return null;
  } else {
    return `${subCategory}: `;
  }
}

export function hasHiddenAdjustment(adjustment) {
  const originalData = adjustment?.original_data;
  const originalDataOriginalData = adjustment?.original_data?.original_data;

  if (Object.prototype.hasOwnProperty.call(adjustment, 'isHiddenAdjustment')) {
    return adjustment.isHiddenAdjustment;
  }

  if (
    originalData &&
    Object.prototype.hasOwnProperty.call(originalData, 'isHiddenAdjustment')
  ) {
    return adjustment.original_data.isHiddenAdjustment;
  }

  if (
    originalDataOriginalData &&
    Object.prototype.hasOwnProperty.call(
      originalDataOriginalData,
      'isHiddenAdjustment',
    )
  ) {
    return adjustment.original_data.original_data.isHiddenAdjustment;
  }

  return false;
}

export function isRoundingRuleAdjustment(adjustment) {
  const originalData = adjustment?.original_data;
  const originalDataOriginalData = adjustment?.original_data?.original_data;

  if (adjustment?.originalCategory) {
    return adjustment.originalCategory == PERuleCategories.Rounding;
  }

  if (originalData?.originalCategory) {
    return originalData.originalCategory == PERuleCategories.Rounding;
  }

  if (originalDataOriginalData?.originalCategory) {
    return (
      originalDataOriginalData.originalCategory == PERuleCategories.Rounding
    );
  }
  return false;
}

export function getUnmodifiedLockPeriod(loanData) {
  // use loanData representation from the pricing store (getters['pricing/loanData'])
  // and return the lock period last used for pricing (Right now, this is the unmodified
  // value on the initial lock. In the future, this will be the unmodified value on any
  // request including `currentRequest`. This is not the case currently due to an error
  // in implementing the overrides which means that older data is not preserving the
  // original state of the lock period on post lock actions. If we do not choose to
  // write a script to fix the old data, we'll need to wait until all older locks
  // expire around a year from now ~ 2024-02-12).
  const { initialLock } = loanData?.lockRequests || {};
  return initialLock?.unmodifiedLockPeriod ?? initialLock?.lockPeriod;
}

export async function fetchWorkflowPolicies(
  loanData,
  currentChangesetId,
  fetchWorkflowPermissionsFunction,
  currentProductId = null,
) {
  const {
    currentLock,
    initialLock,
    pendingLock,
    cancelledLock,
    bundlePendingLock,
  } = loanData?.lockRequests || {};
  let productId;
  let configId;

  if (pendingLock?.isQueued) {
    productId = pendingLock.pe3ProductId;
    configId = pendingLock.changesetId;
  } else if (bundlePendingLock?.isQueued) {
    // TODO I don't see bundlePendingLock anywhere else in the code. Can we kill this?
    productId = bundlePendingLock.pe3ProductId;
    configId = bundlePendingLock.changesetId;
  } else if (pendingLock || cancelledLock || currentLock || initialLock) {
    const lockRequest =
      pendingLock ||
      cancelledLock ||
      currentLock ||
      initialLock ||
      bundlePendingLock;

    productId =
      lockRequest?.productId ||
      getPE3ResultFromLockRequest(lockRequest)?.id ||
      currentProductId;

    configId = lockRequest.changesetId || currentChangesetId;
  }

  if (productId && configId) {
    await fetchWorkflowPermissionsFunction({
      productId,
      configId,
      useActiveSequences: true,
    });
  }
}

export async function fetchPolicies(
  configId,
  productId,
  useActiveSequences = false,
) {
  try {
    const url = api.constructUrl(
      `/pe/api/configurations/${configId}/products/${productId}/policies`,
      useActiveSequences ? { use_active_sequences: true } : {},
    );
    const response = await api.get(url);

    return [...response.policies];
  } catch (error) {
    onErrorHandler('pe-get-product-policies', error);
  }
}

export function filterRelockOptions(
  relockOptions,
  relockPolicy,
  initialLockPeriod,
  daysExpiredOrCancelled,
) {
  const isRelockPeriodValidByOperator = (
    relockPeriod,
    operator,
    daysToCompare,
  ) => {
    if (
      (operator === OP_NAME.GT && relockPeriod <= daysToCompare) ||
      (operator === OP_NAME.GTE && relockPeriod < daysToCompare) ||
      (operator === OP_NAME.LTE && relockPeriod > daysToCompare) ||
      (operator === OP_NAME.LT && relockPeriod >= daysToCompare) ||
      (operator === OP_NAME.EQ && relockPeriod !== daysToCompare)
    ) {
      return false;
    }
    return true;
  };
  const isRelockPeriodValidForPolicy = fee => {
    const relockPeriod = fee.id;
    if (relockPolicy?.relockDaysExpiredEnabled) {
      if (
        !isRelockPeriodValidByOperator(
          relockPeriod,
          relockPolicy?.relockDaysExpiredOperator,
          daysExpiredOrCancelled,
        )
      ) {
        return false;
      }
    }
    if (relockPolicy?.relockDaysLockedEnabled) {
      if (
        !isRelockPeriodValidByOperator(
          relockPeriod,
          relockPolicy?.relockDaysLockedOperator,
          initialLockPeriod,
        )
      ) {
        return false;
      }
    }
    return true;
  };
  return relockOptions.filter(isRelockPeriodValidForPolicy);
}

var special = [
  'zeroth',
  'first',
  'second',
  'third',
  'fourth',
  'fifth',
  'sixth',
  'seventh',
  'eighth',
  'ninth',
  'tenth',
  'eleventh',
  'twelfth',
  'thirteenth',
  'fourteenth',
  'fifteenth',
  'sixteenth',
  'seventeenth',
  'eighteenth',
  'nineteenth',
];
var deca = [
  'twent',
  'thirt',
  'fort',
  'fift',
  'sixt',
  'sevent',
  'eight',
  'ninet',
];

export function stringifyNumber(n) {
  if (n < 20) return special[n];
  if (n % 10 === 0) return deca[Math.floor(n / 10) - 2] + 'ieth';
  return deca[Math.floor(n / 10) - 2] + 'y-' + special[n % 10];
}

export async function addAllInPriceToLosCustomCredit(
  losLoanId = null,
  isFloatRequest = false,
  lockRequestId = null,
  orgPermissions = null,
  customCreditData = null,
) {
  const customCredit = customCreditData ?? this.customCreditData;

  if (!orgPermissions?.has_all_in_price_enabled || !customCredit) {
    return null;
  }

  if (!losLoanId && !lockRequestId) {
    return;
  }

  try {
    if (!isFloatRequest) {
      return createLockRequestCustomCredit(lockRequestId, customCredit);
    } else {
      return createCustomCreditToFloatRequest(lockRequestId, customCredit);
    }
  } catch (e) {
    onErrorHandler(e, 'pe-update-custom-credit');
  }
}

export function calculateBrokerCompCalculatedAmount(
  brokerCompPlan,
  loanAmount,
) {
  // brokerCompPlan can come in structured as a simple dict or pricer ui structure
  const loanValue = Number(loanAmount);

  let fixedAmount = 0;
  if (brokerCompPlan?.fixedAmount?.value) {
    fixedAmount = +brokerCompPlan.fixedAmount.value;
  } else if (
    brokerCompPlan?.fixedAmount &&
    !isObject(brokerCompPlan.fixedAmount)
  ) {
    fixedAmount = +brokerCompPlan.fixedAmount;
  }

  let compPercent = 0;
  if (brokerCompPlan?.percent?.value) {
    compPercent = +brokerCompPlan.percent.value;
  } else if (brokerCompPlan?.percent && !isObject(brokerCompPlan.percent)) {
    compPercent = +brokerCompPlan.percent;
  }
  compPercent /= 100;

  let compMin = 0;
  if (brokerCompPlan?.minAmount?.value) {
    compMin = +brokerCompPlan.minAmount.value;
  } else if (brokerCompPlan?.minAmount && !isObject(brokerCompPlan.minAmount)) {
    compMin = +brokerCompPlan.minAmount;
  }

  let compMax = Infinity;
  if (brokerCompPlan?.maxAmount?.value) {
    compMax = +brokerCompPlan.maxAmount.value;
  } else if (brokerCompPlan?.maxAmount && !isObject(brokerCompPlan.maxAmount)) {
    compMax = +brokerCompPlan.maxAmount;
  }

  return Math.min(
    Math.max(fixedAmount + loanValue * compPercent, compMin),
    compMax,
  );
}

export function calculateBrokerCompCalculatedAdjustment(
  brokerCompPlan,
  loanAmount,
) {
  if (
    !loanAmount ||
    // check broker comp workflow structure. usually consist of a dict of primitives
    // object check to prevent checking pricer ui structure
    (brokerCompPlan?.paidBy &&
      !isObject(brokerCompPlan?.paidBy) &&
      brokerCompPlan.paidBy !== compPaidBy[compPaidByTypes.LENDER]) ||
    // check pricer ui structure
    (brokerCompPlan?.paidBy?.value &&
      brokerCompPlan?.paidBy.value !== compPaidByTypes.LENDER)
  ) {
    return 0;
  }
  return (
    (calculateBrokerCompCalculatedAmount(brokerCompPlan, loanAmount) /
      Number(loanAmount)) *
    100
  );
}

/**
 * Gets string with info about who and when last updated publish settings
 * @param {String} orgTimezone timezone of the current user org
 * @param {Object} publishSettings instance of publish settings
 * @returns {String} publishSettings clamp adjustments for a particular price
 */
export function getPublishSettingsLastUpdated(orgTimezone, publishSettings) {
  if (!orgTimezone || isEmpty(publishSettings)) return null;

  const date_timezone = formatInTimeZone(
    publishSettings?.updated_at,
    'MM/dd/yyyy hh:mm aa',
    orgTimezone,
  );

  const [formattedDate, formattedTime, formattedPeriod] =
    date_timezone.split(' ');
  const abbreviatedTimezone = getAbbreviatedTimezone(orgTimezone);
  return `${publishSettings?.updated_by_full_name} on ${formattedDate} @ ${formattedTime} ${formattedPeriod} ${abbreviatedTimezone}`;
}

export function propertyDataHasChanged(lastPropertyData, propertyData) {
  if (!lastPropertyData || !propertyData) return false;
  const propertyFieldsToCheck = [
    'addressLine1',
    'city',
    'county',
    'state',
    'zipCode',
  ];
  for (const field of propertyFieldsToCheck) {
    if (lastPropertyData[field] !== propertyData[field]) {
      return true;
    }
  }
  return false;
}

/**
 * Gets closest price with rate or netPrice value to given goal value
 *
 * Rate sort logic:
 * 1. Always choose closest rate value to the goal but below (min)
 * 2. If no value below goal - min is undefined, and max is the closest value above
 * 3. Rate value is always unique for the list of prices
 *
 * Price sort logic:
 * 1. Always choose value above with the LOWEST RATE (max)
 * 2. If no value above, return closest below (min), max is undefined
 * 3. If 2 equal prices found below, return one with the lowest rate (min)
 *
 * NOTE: This method duplicates logic in backend get_closest_price utils method,
 *     that is used for server-side sorting. If you modifying this one, you might want
 *     to modify BE logic as well.
 *
 * @param {Array<Object>} prices list of price objects with rate and netPrice attributes
 * @param {Number} goal value to find the closest to
 * @param {Boolean} getByPrice boolean to sort prices by netPrice or by rate (rate by default)
 * @return {{min, max}} Object with min and max price objects.
 *                      Min is closest, but below; Max is closest, but above.
 */
export function getNearestPrice(prices, goal, getByPrice = false) {
  let max;
  let maxAbs = Infinity;
  let min;
  let minAbs = Infinity;
  const sort = getByPrice ? 'netPrice' : 'rate';
  for (const price of prices) {
    const diffCurr = price[sort] - goal;
    if (
      diffCurr <= 0 &&
      Math.abs(diffCurr) <= minAbs &&
      // Skip if both equally close, but rate is higher
      !(Math.abs(diffCurr) === minAbs && price?.rate > min?.rate)
    ) {
      min = price;
      minAbs = Math.abs(diffCurr);
    }
    if (
      diffCurr >= 0 &&
      (!max ||
        (!getByPrice && Math.abs(diffCurr) <= maxAbs) ||
        (getByPrice && price?.rate < max?.rate))
    ) {
      max = price;
      maxAbs = Math.abs(diffCurr);
    }
  }
  return { min: min, max: max };
}

/**
 * Get target sort price for the given product
 *
 * @param {Object} eligProduct product object to get the price for, must contain list of prices
 * @param {Number} sortPrice value provided to sort by price
 * @param {Number} sortRate value provided to sort by rate
 * @return {Object} Target price object. Closest below if sorted by rate or closest above is sorted by price
 */
export function getTargetSortPrice(eligProduct, sortPrice, sortRate) {
  if (sortPrice !== 100 && (!!sortPrice || !!sortRate)) {
    if (sortRate) {
      const { min, max } = getNearestPrice(eligProduct.prices, sortRate);
      return min || max;
    } else {
      const { min, max } = getNearestPrice(eligProduct.prices, sortPrice, true);
      return max || min;
    }
  }
  return eligProduct.parPrice;
}

export function getDisplayLoanType(loanType) {
  return (Object.entries(loanTypes).find(value => {
    return value[1] === loanType;
  }) || [null, null])[0];
}

export function validatePasteNumbers(event) {
  const pasteData = event.clipboardData.getData('text/plain');
  if (!pasteData.match(/^\d+(\.\d+)?$/)) {
    event.preventDefault();
  }
}
